import React, { useState, useEffect } from 'react';
import './index.css';
import { useHistory } from 'react-router-dom';
import isEmailAllowed from './conestents';

const DragAndDrop = ({ onFileDrop }) => {
  const [dragging, setDragging] = useState(false);
  const [fileName, setFileName] = useState('');
  const history = useHistory();
  

  useEffect(() => {
    // Implement your user permission check logic here
    const isUserAllowed = checkUserPermission(); // Replace with your actual permission check function

    if (!isUserAllowed) {
      // Redirect the user to a different page or show an error message
      console.log("User is not allowed on this page!");
      alert("User is not allowed on this page!");
      history.push('/signin');
      // You can use a library like React Router to navigate to a different page
      // Example: history.push('/unauthorized');
    }
  }, []); // The empty dependency array ensures that this effect runs only once on mount

  const checkUserPermission = () => {
    const storedUserInfo = localStorage.getItem('googleSignInInfo');

    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);
      console.log(userInfo);
      // return userInfo && userInfo.email === 'varanasisairock@gmail.com';
      if (isEmailAllowed(userInfo.email)) {
        return true;
      }
    }

    return false;
  };

  const handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', e.target.id);
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;

    if (files.length > 0) {
      const file = files[0];
      setFileName(file.name);
      onFileDrop(file);
    }

    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputChange = (e) => {
    const fileInput = e.target;
    const files = fileInput.files;

    if (files.length > 0) {
      const file = files[0];
      setFileName(file.name);
      onFileDrop(file);
    }
  };

  return (
    <div className='draganddrop'>
      <h1 className='head'>Drag and Drop</h1>
      <div
        className={`drop-zone ${dragging ? 'dragging' : ''}`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div
          id="drag-item"
          draggable
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          className='text'
        >
          Drag
        </div>
      </div>
      <input
        type="file"
        className="file-input"
        onChange={handleFileInputChange}
      />
      {fileName && (
        <p className='file-name'>File Name: {fileName}</p>
      )}
    </div>
  );
};

export default DragAndDrop;


// import React, { useState } from 'react';
// import './index.css';

// const DragAndDrop = ({ onFileDrop }) => {
//   const [dragging, setDragging] = useState(false);
//   const [fileName, setFileName] = useState('');
//   const handleDragStart = (e) => {
//     e.dataTransfer.setData('text/plain', e.target.id);
//     setDragging(true);
//   };

//   const handleDragEnd = ({ onFileDrop }) => {
//     setDragging(false);
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     const files = e.dataTransfer.files;
    
//     if (files.length > 0) {
//       const file = files[0];
//       setFileName(file.name);
//       onFileDrop(file);
//     }

//     setDragging(false);
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//   };

//   return (
//     <div className='draganddrap'>
//       <h1 className='head'>Drag and Dropp </h1>
//       <div
//         className={`drop-zone ${dragging ? 'dragging' : ''}`}
//         onDrop={handleDrop}
//         onDragOver={handleDragOver}
//       >
//         <div
//           id="drag-item"
//           draggable
//           onDragStart={handleDragStart}
//           onDragEnd={handleDragEnd}
//           className='text'
//         >
//           Drag 
//         </div>
//       </div>
//       {fileName && (
//           <p className='file-name'>File Name: {fileName}</p>
//         )}
//     </div>
//   );
// };

// export default DragAndDrop;