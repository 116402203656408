import React, { useState } from 'react';
import Navbar from './Navbar';
import { BrowserRouter as Router, Route,Redirect, Switch } from 'react-router-dom';
import './index.css';
import DragAndDrop from './DragAndDrop';
import { uploadFileToAzure } from './drag';
import SignInPage from './SignInPage';

function App() {
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileUpload = async (file) => {
    const fileName = file.name;

    try {
      const result = await uploadFileToAzure(fileName, file);
      console.log(result);

      if (result.success) {
        setUploadedFile({
          fileName: result.name,
          fileLink: result.url,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCopyLink = () => {
    if (uploadedFile) {
      navigator.clipboard.writeText(uploadedFile.fileLink)
        .then(() => {
          console.log('Link copied.');
          alert('Link copied.'); // Display an alert
        })
        .catch((error) => {
          console.error('Error copying link to clipboard:', error);
          alert('Error copying link to clipboard'); // Display an alert for errors
        });
    }
  };

  const handleLogout = () => {
    // Clear Google sign-in info from local storage
    localStorage.removeItem('googleSignInInfo');

    // Redirect to the sign-in page
    window.location.href = '/signin';
  };


  return (
    <Router>
      <div className="App">
        <Navbar>           
        </Navbar>
        <div className='content'>
          <Switch>
            {/* <Route path='/'>
            <SignInPage  />
            </Route> */}
            <Route path='/create'>
                  <DragAndDrop onFileDrop={handleFileUpload}  />
                  {uploadedFile && (
                    <div>
                      <h2>Uploaded File Details</h2>
                      <p>File Link: {uploadedFile.fileLink}</p>
                      <button onClick={handleCopyLink} className='copy'>Copy Link</button>
                    </div>
                  )}
                  <button onClick={handleLogout} className='logout-button'>Logout</button>
            </Route>
            <Route path='/signin'>
              <div>
              <SignInPage />              
                </div>              
            </Route>
            {/* Redirect to the sign-in page by default */}
            <Redirect from="/" to="/signin" />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
