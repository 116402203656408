import React from 'react';

const Logo = () => {
  return (
    <div className='flex'>
      <img  src="https://www.spotmies.com/spotmies_banner.png" width={200} />
    </div>
  );
};

export default Logo;
