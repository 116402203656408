// import { GoogleLogin } from '@react-oauth/google';
// import { jwtDecode } from 'jwt-decode';
// import { useHistory } from 'react-router-dom'; // Import useHistory
// import './index.css';

// export default function SignInPage() {
//   const history = useHistory(); // Initialize useHistory

//   return (
//     <div>
//       <GoogleLogin
//         id="google-login"
//         buttonText="Sign in with Google"
//         onSuccess={(credentialResponse) => {
//           const credentialResponseDecoded = jwtDecode(
//             credentialResponse.credential
//           );
//           console.log(credentialResponseDecoded);

//           // Save Google sign-in info to local storage
//           localStorage.setItem('googleSignInInfo', JSON.stringify(credentialResponseDecoded));

//           // Navigate to the '/create' route
//           history.push('/create');
//         }}
//         onError={() => {
//           console.log('Login Failed');
//         }}
//         cookiePolicy={'single_host_origin'}
//       />
//     </div>
//   );
// }
import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import './index.css';
import isEmailAllowed from './conestents';

const SignInPage = () => {
  const history = useHistory();

  const handleGoogleSignIn = async (credentialResponse) => {
    const credentialResponseDecoded = jwtDecode(credentialResponse.credential);
    console.log(credentialResponseDecoded);

    // Check if the signed-in user's email is allowed
    if (isEmailAllowed(credentialResponseDecoded.email)) {
      // Save Google sign-in info to local storage
      localStorage.setItem('googleSignInInfo', JSON.stringify(credentialResponseDecoded));

      // Navigate to the '/create' route
      history.push('/create');
    } else {
      // Display an alert if the email is not allowed
      alert('You are not allowed.');
    }
  };

  const handleGoogleSignInError = () => {
    console.log('Login Failed');
  };

  return (
    <div className="ehe">
       <div className="signinpage">
       </div>
    <div className='google-signin'>
    <GoogleLogin
      id="googlebutton"
      buttonText="Sign In"
      onSuccess={handleGoogleSignIn}
      onError={handleGoogleSignInError}
      cookiePolicy={'single_host_origin'}
    />
    </div>
  </div>
   
  );
};

export default SignInPage;
