const isEmailAllowed = (email) => {
  // Add your logic to check if the email is allowed
  // For example, check against a list of allowed emails
  const allowedEmails = [
    'varanasisairock@gmail.com',
    'saivaranasi056@gmail.com',
    'satish@spotmies.com',
    'santhoshikumarivaranasi@gmail.com',
    'sekharatece@gmail.com',
    'akashpolarouthu111@gmail.com',
    '5starmohith@gmail.com',
    'isayipavankumar2@gmail.com',
    'vignyadiamond.2004@gmail.com',
    'sivakrishan2701@gmail.com',
    'guttulaharshakumar@gmail.com',
    'sesettikalyan@gmail.com',
    'krishnamanasrajgari2006@gmail.com',
    'karthiks1mail@gmail.com',
    'saragadamsaiprasad99@gmail.com',
    'nagireddydeepakchandra@gmail.com',
    'djoshnavi@gmail.com',
    'gr8jagadeesh@gmail.com',
    'gvamsikrishna.0420@gmail.com',
    'jitendraboddapu2727@gmail.com',
    'usha78633@gmail.com',
    'andhavarapu.nikhil21@gmail.com',
    'gelamsumanjali313@gmail.com',
    'rakeshpatlasingh@gmail.com',
    'hemambika2004@gmail.com',
    'jayasree3388@gmail.com',
    'challaabi12@gmail.com',
    'bmvarshika@gmail.com',
    'venkatadevashis26@gmail.com',
    'vaddidinesh16aug@gmail.com',
    'arpita.brj2003@gmail.com',
    'manasmalla.dev@gmail.com',
    'ragamalika232@gmail.com',
    'shaikashraaf3598@gmail.com',
    'bharathchandu819@gmail.com',
    'k.saiteja456@gmail.com',
    'anilbalaka0515@gmail.com',
    'harshinee310@gmail.com',
    'mohanshankarmummana@gmail.com',
    'serinacharis@gmail.com',
    'aravindballa2002@gmail.com',
    'venkataswaroop18@gmail.com',
    'uyyurisravya@gmail.com',
    'sravanthisarvasiddi@gmail.com',
    'likkimanu1212@gmail.com',
    'manoharlikhit@gmail.com',
    'saisrujanasaladi@gmail.com',
    'gsspdevi123@gmail.com',
    'likhithakancharla4@gmail.com',
    'skhaneesha26@gmail.com',
    'achchayani60@gmail.com',
    'karrivisalakshi09@gmail.com',
    'putsalaharshapriya@gmail.com',
    'hemasaiamujuri3@gmail.com',
    'charannaidu1512@gmail.com',
    'burguladhanush2003@gmail.com',
    'sekhar@spotmies.com',
    '70956naveen@gmail.com',
    'lithuzzz2001@gmail.com',
    'niranjan.salihundam@gmail.com',
    ''
  ];
  return allowedEmails.includes(email);
};

export default isEmailAllowed;

