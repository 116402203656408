import { BlobServiceClient, AnonymousCredential } from "@azure/storage-blob";
window.Buffer = window.Buffer || require("buffer").Buffer;

export async function uploadFileToAzure(fileName, file) {
  return new Promise(async (resolve, reject) => {
    if (!file) {
      console.error("No file selected");
      resolve({
        error: "No file selected",
      });
    }

    const accountName = "reaidystorage";
    const containerName = "spotmiespublic";
    const sasToken =
      // "sp=racw&st=2023-12-27T07:06:18Z&se=2024-12-27T15:06:18Z&sv=2022-11-02&sr=c&sig=pJOtMDyo%2FtYA%2Bsa4IWJ%2FAltt8p4B5zbOm0zNmJLHeD4%3D";
      // "sp=racwdl&st=2024-02-13T07:26:27Z&se=2025-02-13T15:26:27Z&sv=2022-11-02&sr=c&sig=ORad58LtUshdrH3hjXyO7RJlyblXK7fwznqKvTq4Jlo%3D";
      "sp=racwdli&st=2024-03-21T08:26:22Z&se=2025-03-20T16:26:22Z&sv=2022-11-02&sr=c&sig=yRD2NlA8HVZmauJ4I7EzhCxTGbYRp2rVEcbl2PpXveg%3D";

    const blobServiceClient = new BlobServiceClient(
      `https://${accountName}.blob.core.windows.net?${sasToken}`,
      new AnonymousCredential()
    );

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobName = fileName;
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    try {
      let temp = await blockBlobClient.uploadData(file);
      console.log("Image uploaded successfully!", temp);
      console.log(
        `https://reaidystorage.blob.core.windows.net/spotmiespublic/${fileName}`
      );
      resolve({
        // success: "Image uploaded successfully!",
        url: `https://reaidystorage.blob.core.windows.net/spotmiespublic/${fileName}`,
        data: temp,
        success: true,
      });
    } catch (error) {
      console.error("Error uploading image:", error.message);
      resolve({
        error: "Error uploading image:",
        data: error,
        success: false,
      });
    }
  });
}